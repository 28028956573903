body,
html {
  width: 100%;
  margin: 0;
  background-color: #000;
}

html,
body,
#root {
  height: 100%;
}
